var levelRectangle = Vue.extend({
    name    : 'level-rectangle',
    template: '#level-rectangle',

    props: [
        'data'
    ],

    computed: {
        style: function () {
            return {
                'width': this.data.width + 'px',
                'height': this.data.height + 'px',                
                'background-color': this.data.color,
                'border-left': this.data.borders['border-left'],
                'border-right': this.data.borders['border-right'],
                'border-top': this.data.borders['border-top'],
                'border-bottom': this.data.borders['border-bottom'],
                'cursor': (this.mode.type === null) ? 'auto' : this.cursors[this.mode.type],
                'opacity': this.data.opacity,
            };
        },

        areaStyle: function() {
            return {
                'top': this.data.top - this.config.padding + 'px',
                'left': this.data.left - this.config.padding + 'px',
                'padding': this.config.padding + 'px',
                'cursor': (this.mode.type === null) ? 'auto' : this.cursors[this.mode.type],
                'z-index': this.mode.locked ? '1000' : '10',
            };
        }
    },

    data: function() {
        return {
            config: {
                padding: 15,
                borderStyle: '3px #000 solid',
            },

            mode: {
                locked: false,
                type: null,
            },

            mouse: {
                lockPosition: null,
                initialTop: null,
                initialLeft: null,
                initialWidth: null,
                initialHeight: null,
            },

            cursors: {
                move: 'move',
                'resize-left': 'ew-resize',
                'resize-right': 'ew-resize',
                'resize-top': 'ns-resize',
                'resize-bottom': 'ns-resize',
            }
        }
    },

    methods: {
        getMousePosition: function(e) {
            var $el = $(this.$el);
            var offset = $el.offset();

            return {
                x: e.pageX - offset.left,
                y: e.pageY - offset.top,
            };
        },

        mouseMove: function(e) {
            var pos = this.getMousePosition(e);
            this.clearBorders();

            if (!this.mode.locked) {
                this.processBorders(pos);
            } else {
                this.proccessMove(pos);
            }
        },

        mouseLeave: function(e) {
            this.clearBorders();
        },

        mouseDown: function(e) {
            this.mode.locked = true;
            this.mouse.lockPosition = this.getMousePosition(e);
            this.mouse.initialTop = this.data.top;
            this.mouse.initialLeft = this.data.left;
            this.mouse.initialWidth = this.data.width;
            this.mouse.initialHeight = this.data.height;
        },

        mouseUp: function() {
            this.mode.locked = false;
            this.mouse.lockPosition = null;
        },

        clearBorders: function() {
            this.data.borders = { 
                'border-left': 'none',
                'border-right': 'none',
                'border-top': 'none',
                'border-bottom': 'none',
            };
        },

        processBorders: function(pos) {
            var margin = 5;

            this.mode.type = 'move';

            // left border
            if (pos.x <= (this.config.padding + margin)) {
                this.data.borders['border-left'] = this.config.borderStyle;
                this.mode.type = 'resize-left';
            }

            // right border
            var rightLimit = this.config.padding + this.data.width - margin;
            if (pos.x >= rightLimit) {
                this.data.borders['border-right'] = this.config.borderStyle;
                this.mode.type = 'resize-right';
            }

            // top border
            if (pos.y <= (this.config.padding + margin)) {
                this.data.borders['border-top'] = this.config.borderStyle;
                this.mode.type = 'resize-top';
            }

            // bottom border
            var bottomLimit = this.config.padding + this.data.height - margin;
            if (pos.y >= bottomLimit) {
                this.data.borders['border-bottom'] = this.config.borderStyle;
                this.mode.type = 'resize-bottom';
            }
        },

        proccessMove: function(pos) {
            var dx = pos.x - this.mouse.lockPosition.x;

            var dy = pos.y - this.mouse.lockPosition.y;

            if (this.mode.type == 'move') {
                this.data.left += dx;
                this.data.top += dy;
            }

            if (this.mode.type == 'resize-left') {
                this.data.left += dx;
                this.data.width -= dx;
            }

            if (this.mode.type == 'resize-right') {
                this.data.width = this.mouse.initialWidth + dx;
            }

            if (this.mode.type == 'resize-top') {
                this.data.top += dy;
                this.data.height -= dy;
            }

            if (this.mode.type == 'resize-bottom') {
                this.data.height = this.mouse.initialHeight + dy;
            }
        }
    }
});

var levelMaker = Vue.extend({
    name    : 'level-maker',
    template: '#level-maker',

    data: function() {
        return {
            hasImageToUpload: false,
            hasUploaded: false,
            imageFile: null,
            urls: handover.urls,

            image: {
                src: null,
                id: null,
            },

            bottle: null,
            easterEgg: null,


            difficulty: 1,

            easterEggs: handover.easterEggs,
            selectedEasterEgg: null,
        }
    },

    computed: {
        rectangles: function() {
            if (this.easterEgg == null) {
                return [this.bottle];
            }

            return [this.easterEgg].concat([this.bottle]);
        },

        hasEasterEgg: function() {
            return this.easterEgg !== null;
        },

        form: function() {
            return {
                bottle: this.bottle,
                easterEgg: this.easterEgg,
                difficulty: this.difficulty,
                imgId: this.image.id,
                selectedEasterEgg: this.selectedEasterEgg,
            }
        }
    },

    methods: {
        insertedImage: function(event) {
            this.hasImageToUpload = true;
            this.imageFile = event.target.files[0]
        },

        insertEasterEggRectangle: function(event) {
            this.easterEgg = this.newRectangle();
        },

        insertBottleRectangle: function() {
            this.bottle = this.newRectangle();
        },

        newRectangle: function() {
            return {
                width: Math.floor(Math.random()*150) + 20,
                height: Math.floor(Math.random()*150) + 20,
                top: Math.floor(Math.random()*150),
                left: Math.floor(Math.random()*150),
                color: '#'+Math.floor(Math.random()*16777215).toString(16),
                opacity: '0.7',
                borders: {
                    'border-left': 'none',
                    'border-right': 'none',
                    'border-top': 'none',
                    'border-bottom': 'none',
                },
            };
        },

        deleteEasterEgg: function(id) {
            this.easterEgg = null
        },

        uploadImage: function(event) {

            var formData = new FormData();
            formData.append('image', this.imageFile);

            var that = this;
            $.ajax({
                type: 'POST',
                url: this.urls.uploadImage,
                data: formData,
                processData: false,
                contentType: false,
                dataType: 'json',
                success: function (data) {
                    that.image.src = data.imgPath;
                    that.image.id = data.mediaFile.model.id;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                },
                complete: function(jqXHR, textStatus) {
                    that.hasUploaded = true;
                }
            });
        },

        save: function(event) {
            $.ajax({
                type: 'POST',
                url: this.urls.saveLevel,
                data: this.form,
                dataType: 'json',
                success: function (data) {
                    alert('porreiro pa!');
                    location.reload();
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    alert('Erro fatal. Faz debug à la pate se quiseres');
                },
                complete: function(jqXHR, textStatus) {
                }
            });
        }
    },

    components: {
        'level-rectangle': levelRectangle,
    },

    beforeCompile: function () {
        this.insertBottleRectangle();
    }
});