var modalGiveUp = Vue.extend({
    name    : 'modal-giveup',
    template: '#modal-giveup',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-modal', null);
        },
        giveUp: function() {
            this.$dispatch('gave-up');
        }
    }
});

var modalEasterEgg = Vue.extend({
    name    : 'modal-easteregg',
    template: '#modal-easteregg',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-modal', null);
        }
    }
});


var modalTimesUp = Vue.extend({
    name    : 'modal-timesup',
    template: '#modal-timesup',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-timesup', null);
        },
        nextStep: function() {
            this.$dispatch('resolve-finished', null);
        }
    }
});

var modalLostGame = Vue.extend({
    name    : 'modal-lost-game',
    template: '#modal-lost-game',

    props: ['data'],

    methods: {
        goBack: function(event) {
            this.$dispatch('close-go-back', null);
        },
        newGame: function(event) {
            this.$dispatch('close-new-game', null);
        }
    }
});

var modalFinishGame = Vue.extend({
    name    : 'modal-finish-game',
    template: '#modal-finish-game',

    props: ['data'],

    methods: {
    }
});

var modalSubscribeNewsletter = Vue.extend({
    name    : 'modal-subscribe-newsletter',
    template: '#modal-subscribe-newsletter',

    props: ['data', 'game-session'],

    data: function() {
        return {
            form: {
                name: handover.user.name,
                email: handover.user.email,
                gender: [],
                profile: null,
                info: false,
                accept: false,
            },
        }
    },

    methods: {
        subscribeNewsletter: function(event) {
            var that = this;
            MyAPI.subscribeNewsletter(this.gameSession, this.form, function(status, data) {
                if (status == 'success') {
                    that.$dispatch('subscribed-newsletter', data);
                }
            });
        },
        continueGame: function(event) {
            this.$dispatch('lost-game', this.data);
        }

    }
});

var modalInviteFriend = Vue.extend({
    name    : 'modal-invite-friend',
    template: '#modal-invite-friend',

    props: ['data', 'game-session'],

    data: function() {
        return {
            email: null
        }
    },

    methods: {
        inviteFriend: function(event) {
            var that = this;
            MyAPI.inviteFriend(this.gameSession, {email: this.email}, function(status, data) {
                if (status == 'success') {
                    that.$dispatch('invited-friend', data);
                }
            });
        },
        nextStep: function() {
            this.$dispatch('lost-game', this.data);
        }
    }
});

var modalFoundBottle = Vue.extend({
    name    : 'modal-found-bottle',
    template: '#modal-found-bottle',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-timesup', null);
        }
    }
});

var modalFoulPlay = Vue.extend({
    name    : 'modal-foul-play',
    template: '#modal-foul-play',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-timesup', null);
        }
    }
});

var modalBusy = Vue.extend({
    name    : 'modal-busy',
    template: '#modal-busy',

    props: ['data'],
});

var modalExtraLife = Vue.extend({
    name    : 'modal-extra-life',
    template: '#modal-extra-life',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-timesup', null);
        }
    }
});

var modalEasterEggPrizes = Vue.extend({
    name    : 'modal-easteregg-prizes',
    template: '#modal-easteregg-prizes',

    props: ['data'],

    methods: {
        nextStep: function(event) {
            this.$dispatch('resolve-finished', null);
        }
    }
});

var modalMiss = Vue.extend({
    name    : 'modal-miss',
    template: '#modal-miss',

    props: ['data'],

    methods: {
        continueGame: function(event) {
            this.$dispatch('close-modal', null);
        }
    }
});

var gameModal = Vue.extend({
    name    : 'game-modal',
    template: '#game-modal',

    props: ['component', 'component-data', 'game-session'],

    data: function() {
        return {
        }
    },

    computed: {
        loading: function() {
            return this.component === null
        }
    },

    components: {
        easterEgg           : modalEasterEgg,
        timesUp             : modalTimesUp,
        foundBottle         : modalFoundBottle,
        lostGame            : modalLostGame,
        foulPlay            : modalFoulPlay,
        inviteFriend        : modalInviteFriend,
        subscribeNewsletter : modalSubscribeNewsletter,
        finishGame          : modalFinishGame,
        busy                : modalBusy,
        extraLife           : modalExtraLife,
        easterPrizes        : modalEasterEggPrizes,
        miss                : modalMiss,
        giveUp              : modalGiveUp,
    }
});