var MyAPI = {
    urls: handover.urls.API,

    voting: false,

    doRequest: function (type, url, data, callback) {
        if (type == 'POST') {
            data._token = handover.token;
        }

        $.ajax({
            type: type,
            url: url,
            data: data,
            dataType: 'json',
            success: function (data) {
                callback('success', data);
            },
            error: function (jqXHR, textStatus, errorThrown) {
                callback('error', jqXHR);
            },
        });
    },

    newGame: function(callback) {
        this.doRequest('GET', this.urls['new-game'], null, callback);
    },

    continueGame: function(callback) {
        this.doRequest('GET', this.urls['continue-game'], null, callback);
    },

    getLevel: function(gameSession, callback) {
        var url = this.urls['level'];
        url = url.replace(":session:", gameSession);

        this.doRequest('GET', url, null, callback);
    },

    postLevel: function(gameSession, data, callback) {
        var url = this.urls['level'];
        url = url.replace(":session:", gameSession);

        var time = 0;

        data.plays.forEach(function(play) {
            time += play.a
        });

        data.c = md5(data.plays[0].b + time + handover.token)

        this.doRequest('POST', url, data, callback);
    },

    subscribeNewsletter: function(gameSession, data, callback) {
        var url = this.urls['subscribe-newsletter'];
        url = url.replace(":session:", gameSession);

        this.doRequest('POST', url, data, callback);
    },

    inviteFriend: function(gameSession, data, callback) {
        var url = this.urls['invite-friend'];
        url = url.replace(":session:", gameSession);

        this.doRequest('POST', url, data, callback);
    },

    getGlobalData: function(callback) {
        var url = this.urls['global-data'];
        this.doRequest('GET', url, null, callback);
    },
    getRanking: function(callback) {
        var url = this.urls['ranking'];
        this.doRequest('GET', url, null, callback);
    },
    postReclaimPrize: function(callback) {
        var url = this.urls['reclaim'];
        this.doRequest('POST', url, null, callback)
    },
    sendShareMail: function(data, callback) {
        var url = this.urls['share-email'];

        this.doRequest('POST', url, data, callback)
    },
    toggleWinner: function(data, callback) {
        var url = this.urls['week-winner'];
        this.doRequest('POST', url, data, callback)
    }
};
