// Vue.config.debug = true

var app = new Vue({
    name: 'app',

    el: '#app',

    data: {
        senderMail: '',
        receiverMail: '',
        isLogged: false,
        maxLevel: null,
        sessionTime: null,
        easterEggsFound: null,
        gameSessionId: null,
        isMobile: (window.innerWidth < 767),
        openMenu: false,
        showShareMail: false,
        showShareSuccess: false,
        showShareFail: false,
        showHowTo: false,
        showEggs: false,
        showAwards: false,
        showRanking: false,
        showGame: false,
        showOver: false,
        showGratters: false,
        showWow: false,
        showWin: false,
        showWinPlusEaster: false,
        showOverPlusEaster: false,
        showReclaimForm: false,
        showFacebookLogin: false,
        showForm: false,
        footer: null
    },

    computed: {
        showStats: function() {
            return this.sessionTime !== null && this.maxLevel !== null && this.easterEggsFound !== null
        },
        backgrd: function() {
            return {
                'background-image': this.isMobile ?
                    'url(img/backgrd-' + handover.locale.key + '-mobile.jpg)' :
                    'url(img/backgrd-' + handover.locale.key + '-desktop.jpg)',
            };
        },
    },

    components: {
        'game-zone': gameZone,
        'level-maker': levelMaker,
        'ranking': ranking,
    },

    methods: {
        handleResize: function() {
            this.isMobile = (window.innerWidth < 767);
        },
        menuMobile: function() {
            this.openMenu = !this.openMenu;
        },
        runMail: function() {
            this.showShareMail = true;
        },
        runHowTo: function() {
            this.openMenu = false;
            this.showHowTo = true;
            reloadSlider();
        },
        runEggs: function(event) {
            if( $(event.target).hasClass('link')) {
                this.showEggs = true;
                reloadSlider();
            }
        },
        runAwards: function() {
            this.openMenu = false;
            this.showAwards = true;
            reloadSlider();
        },
        runRanking: function() {
            this.openMenu = false;
            this.showRanking = true;
        },
        closeArea: function() {
            this.showHowTo = false;
            this.showAwards = false;
            this.showRanking = false;
            this.showShareMail = false;
            this.showShareSuccess = false;
            this.showShareFail = false;
        },
        closeEggs: function() {
            this.showEggs = false;
            reloadSlider();
        },
        runGame: function() {
            this.closeFinalPanels();
            this.showGame = !this.showGame;
            this.$broadcast('start-game');
        },
        continueGame: function() {
            this.closeFinalPanels();
            this.showGame = !this.showGame;
            this.$broadcast('continue-game');
        },
        footerLinks: function(el) {
            this.footer = el;
        },
        footerClose: function(el) {
            this.footer = null;
        },
        goStar: function() {
            this.closeFinalPanels();
        },
        formClose: function(el) {
            this.showForm = false;
        },
        jumpToRanking: function() {
            this.closeFinalPanels();
            this.runRanking();
        },
        /*reclaimPrizes: function() {
            this.showWin = false;
            this.showWinPlusEaster = false;
            this.showOverPlusEaster = false;
            if (!this.isLogged) {
                this.showFacebookLogin = true;
            } else {
                this.showReclaimForm = true;
            }
        },*/
        closeFinalPanels: function() {
            this.showWin = false;
            this.showOver = false;
            this.showWinPlusEaster = false;
            this.showOverPlusEaster = false;
            this.maxLevel = null;
            this.sessionTime = null;
            this.easterEggsFound = null;
        },
        sendShareMail: function() {
            var data = {
                sender: this.senderMail,
                receiver: this.receiverMail,
            }
            var that = this;
            MyAPI.sendShareMail(data, function(status, response) {
                if (status === 'success') {
                    that.showShareMail = false;
                    that.showShareSuccess = true;
                    return;
                }

                that.showShareMail = false;
                that.showShareFail = true;
                return;

            })
        }
    },
    events: {
        'userTouch': function(event) {
            this.$broadcast('touch', event);
        },

        'lost-game': function(data) {
            if (data) {
                this.isLogged = data.logged;
                this.maxLevel = data.gameMaxLevel;
                this.sessionTime = data.gameTime;
                this.easterEggsFound = data.easterEggsFound;
                this.gameSessionId = data.gameSessionId;
                this.showGame = false;
                if (data.foundAllEasterEggs) {
                    this.showOverPlusEaster = true;
                } else {
                    this.showOver = true;
                }
                this.$broadcast('update-rankings')
            } else {
                this.showGame = false;
                this.showOver = true;
            }

        },

        'finish-game': function(data) {
            if (data) {
                this.isLogged = data.logged;
                this.maxLevel = data.gameMaxLevel;
                this.sessionTime = data.gameTime;
                this.easterEggsFound = data.easterEggsFound;
                this.gameSessionId = data.gameSessionId;
                this.showGame = false;
                if (data.foundAllEasterEggs) {
                    this.showWinPlusEaster = true;
                } else {
                    this.showWin = true;
                }
                this.$broadcast('update-rankings')
            } else {
                this.showGame = false;
                this.showOver = true;
            }
        },
        'gave-up': function() {
            this.showGame = false;
            this.$broadcast('reset-game');
        }
    },

    ready: function () {
        window.addEventListener('resize', this.handleResize)
    },
});

$slider = $('#howtoplay .slider, #awards .slider').flickity({
    prevNextButtons: false,
    pageDots: false,
});

$slider.on( 'cellSelect', function() {
    var el = $(this).data('flickity');
    var t = el.selectedCell.element;
    var title = $('.titulo', t ).text();
    var text = $('.texto', t ).html();
    var idx = el.selectedIndex + 1;
    $('.conter .index').text(idx);
    $('.title', el.$element.parent()).text(title);
    $('.text', el.$element.parent()).html(text);

});

$slider.on( 'staticClick', function( event, pointer, cellElement, cellIndex ) {
    if ( typeof cellIndex == 'number' ) {
        $slider.flickity( 'select', cellIndex );
    }
});

function reloadSlider() {
    $('.slider .cell').removeClass('is-selected');
    setTimeout( function(){ $('.conter').fadeTo(200, 0); $slider.flickity('resize'); }, 0);
    setTimeout( function(){ $('.conter').fadeTo(200, 1); $slider.flickity('select', 0, false, true); }, 800);
}

var imgOffsetX = -((1366-$(window).width())/2);
var imgOffsetY = -((768-$(window).height())/2);
function runPan() {
    $('.box-img .frame').panzoom({contain:'invert', disableZoom:true, minScale:1, maxScale:1,});
    if( $(window).width() < 1366 || $(window).height() < 768 ) {
        $('.arraste').addClass('showing');
    }
    touchPos();
}
function resizGame() {
    var w = $(window).width();
    var h = $(window).height();
    $('.box-img').width(w);
    $('.box-img').height(h);
    if( w < 1366 || h < 768 ) {
        $('.box-img').removeClass('fixed');
    } else {
        $('.box-img').addClass('fixed');
    }
    $('.box-img .frame').panzoom('resetDimensions');
    $('.box-img .frame').panzoom('pan', -((1366-w)/2), -((768-h)/2) );
}

function touchPos() {
    $('.box-img .frame').on('panzoompan', function(e, elem, x, y) {
        imgOffsetX = Math.abs(x);
        imgOffsetY = Math.abs(y);
    });
    $('.box-img .frame').on('panzoomend', function(e, elem, matrix, changed) {
        if(!changed){
            var toucheX = e.originalEvent.offsetX
            var toucheY = e.originalEvent.offsetY

            if ($('.box-img').hasClass('fixed')) {
                toucheX = e.offsetX
                toucheY = e.offsetY
            }

            if(e.originalEvent.constructor.name === 'TouchEvent' ){
                var w = $(window).width() - 1366;
                var h = $(window).height() - 768;
                ( w < 0 ) ? w = 0 : w = w;
                ( h < 0 ) ? h = 0 : h = h;
                toucheX = e.changedTouches[0].pageX+imgOffsetX-(w/2);
                toucheY = e.changedTouches[0].pageY+imgOffsetY-(h/2);
                e.clientX = e.changedTouches[0].pageX;
                e.clientY = e.changedTouches[0].pageY;
            }
            e.offsetX = toucheX;
            e.offsetY = toucheY;

            app.$emit('userTouch', e);
        }
    });
}

$(window).on('resize', function() {
    resizGame();
});
