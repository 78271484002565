/*var varRanking =[
    {name:'Julio Geraldo', time:'14:56'},
    {name:'Joaqui Francisco', time:'14:56'},
    {name:'Jacline Jacinta', time:'14:56'},
    {name:'Alfredo Ventoinha', time:'14:56'},
    {name:'João Carlos Fonceca', time:'14:56'},
    {name:'Ana Maria Anabela', time:'14:56'},
    {name:'Nuno Caramelo', time:'14:56'},
    {name:'Almeida Regos', time:'14:56'},
    {name:'Nome do Jogador', time:'14:56'},
    {name:'Nome do Jogador', time:'14:56'},
];


*/
var ranking = Vue.extend({
    name    : 'ranking',
    template: '#tpl-ranking',

    data: function() {
        return {
            general: [],
            weekly: [],
            awarded: [],
            easterEggs: [],
            activeRanking: {
                label: 'Geral',
                value: 'general',
                message: 'Estes são os jogadores mais rápidos a encontrar o cantil de Mateus nos 30 níveis! <br> Consegues destroná-los e ganhar o prémio final? <br> A partir do próximo dia 21 de Dezembro anunciaremos os vencedores.<br> Fica atento ao nosso Facebook. Boa sorte!'
            },
            positions: {
                /*left: {
                    label: 'Semanal',
                    value: 'weekly',
                    message: 'Estes são os jogadores mais rápidos desta semana! <br>  No final da semana anunciamos o vencedor e a classificação final.<br> Fica atento ao nosso Facebook. Boa sorte!'
                },*/
/*                right: {
                    label: 'Easter eggs',
                    value: 'easterEggs',
                    message: 'Estes foram os 50 jogadores a encontrar primeiro todos os Easter Eggs!'
                }*/
            }
        }
    },
    methods: {
        setRankingType: function(type, position) {
            /*this.positions[position] = this.activeRanking;
            this.activeRanking = type;
*/        },
        updateRankings: function() {
            var self = this;
            MyAPI.getRanking(function(status, data) {
                if (status === 'success') {
                    self.general = data.general;
                    self.weekly = data.weekly;
                    self.awarded = data.awarded;
                    self.easterEggs = data.easterEggs;
                    return;
                }
            });
        }
    },
    events: {

        'update-rankings': function() {
            this.updateRankings()
        },
    },
    created: function() {
        this.updateRankings()
    }
});