var gameZone = Vue.extend({
    name    : 'game-zone',
    template: '#game-zone',

    data: function() {
        return {
            level: null,

            urls: handover.urls,

            hasStarted: false,
            hasLevel: false,
            loseGame: false,
            gameSession: null,

            modal: {
                show: false,
                component: null,
                data: null,
            },

            globalData: null
        }
    },


    components: {
        'game-level': gameLevel,
        'game-modal': gameModal,
    },

    methods: {
        startGame: function(callback) {
            this.hasStarted = true;
            this.getGameSession(callback);
        },

        continueGame: function() {
            this.hasStarted = true;

            var that = this;
            MyAPI.continueGame(function(status, data) {
                if (status == 'error') {
                    return;
                }
                that.gameSession = data.hash;

                that.startLevel();
            });
        },

        startLevel: function() {
            var that = this;
            if (this.gameSession === null) {
                this.startGame(function(status, callback) {
                    that.getLevel();
                });
            } else {
                this.getLevel();
            }
        },

        getGameSession: function(callback) {
            var that = this;
            MyAPI.newGame(function(status, data) {
                if (status == 'error') {
                    return;
                }

                that.gameSession = data.hash;
                if (typeof callback !== 'undefined') {
                    callback(status, data);
                }
            });
        },

        getLevel: function(callback) {
            var that = this;
            MyAPI.getLevel(that.gameSession, function(status, data) {
                if (status == 'error') {
                    return;
                }

                that.remainingLives = data.lives;
                that.level = data;
                that.hasLevel = true;

                if (typeof callback !== 'undefined') {
                    callback(status, data);
                }
            });
        },

        showModal: function() {
            this.modal.show = true;
            this.$broadcast('modal-opened');
        },

        resetLevel: function() {
            this.hasStarted = false;
            this.level = null;
            this.hasLevel = false;
        },

        resetAndStart: function() {
            this.hideModal();
            this.resetLevel();
            this.startLevel();
        },

        resetGame: function() {

            this.hideModal();
            this.resetLevel();
            this.gameSession = null;
        },

        setModalAndShow: function(component, data) {
            this.setModal(component, data);
            this.showModal();
        },

        setModal: function(component, data) {
            this.modal.component = component;
            this.modal.data = data;
        },

        hideModal: function() {
            this.modal.show = false;
            this.modal.component = null;
            this.modal.data = null;
            this.loseGame = false;
        }
    },

    events: {
        // Game related:
        'start-game': function() {
            this.startLevel();
            this.loseGame = false;
        },

        'continue-game': function() {
            this.continueGame();
        },

        // Modal related:
        'open-modal': function() {
            this.showModal();
        },

        'set-modal': function(data) {
            if (data.lostLife) {
                this.level.remainingLives--;
                this.loseGame = true;
            }

            this.setModal(data.component, data)
        },

        easteregg: function(data) {
            this.setModalAndShow('easterEgg', data);
        },

        'close-easteregg-prizes': function() {
            this.modal.show = false;
            this.$broadcast('resume-level');
        },

        'close-timesup': function() {
            this.resetAndStart();
        },

        'close-new-game': function() {
            this.gameSession = null;
            this.resetAndStart();
        },

        'close-go-back': function() {
            this.modal.show = false;
        },

        'subscribed-newsletter': function(data) {
            this.setModal('extraLife', data);
        },

        'invited-friend': function(data) {
            this.setModal('extraLife', data);
        },

        'lost-game': function(event) {
            this.resetGame();

            return true;
        },

        'finish-game': function(data) {
            this.resetGame();

            return true;
        },

        'resolve-finished': function(data) {
            this.$broadcast('resolve-finished', null);
        },

        'miss': function(data) {
            this.setModalAndShow('miss', this.globalData.modals.miss);
            this.globalData.missTip = true;
        },

        'give-up': function() {
            this.setModalAndShow('giveUp', this.globalData.modals.giveUp);
        },

        'close-modal': function() {
            this.hideModal();
            this.$broadcast('resume-level');
        },
        'reset-game': function() {
            this.resetGame();
        }
    },
    created: function() {
        var that = this;
        MyAPI.getGlobalData(function (status, response) {
            if (status === 'success') {
                that.globalData = response;
            }
        });
    }
});