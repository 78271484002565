var gameLevel = Vue.extend({
    name    : 'game-level',
    template: '#game-level',

    props: ['data', 'gameSession', 'loseGame'],

    data: function() {
        return {
            ready: false,

            remainingTime: this.data.timer,
            timePenalty: this.data.timePenalty,

            timer: null,

            plays: [],

            foundEasterEgg: false,
            foundBottle: false,
            gameActive: false,
            lessfive: false,

            posY: '0',
            posX: '0',

            lessFiveY: '0',
            lessFiveX: '0',

            initialCountDown: 3,

            style:{
                height: this.bottleHeight,
            },

            finishedLevelData: null
        }
    },

    computed: {
        timerSecs: function() {
            if (typeof this.remainingTime === null) {
                return null;
            }

            if (this.remainingTime < 100) {
                return '00';
            }

            if (this.remainingTime < 1000) {
                return '0' + this.remainingTime.toString().substr(0, 1);
            }

            return this.remainingTime.toString().substr(0, 2);
        },

        timerMilis: function() {
            if (typeof this.remainingTime === null) {
                return null;
            }

            if (this.remainingTime <= 0) {
                return '00';
            }

            if (this.remainingTime < 100) {
                return this.remainingTime.toString().substr(0, 2);
            }

            if (this.remainingTime < 1000) {
                return this.remainingTime.toString().substr(1, 3);
            }

            return this.remainingTime.toString().substr(2, 4);
        },

        bottleHeight: function() {
            if (typeof this.remainingTime === null) {
                return 'auto';
            }
            var conter = 48/this.data.timer;
            return this.remainingTime * conter+'px';
        },

        timePenaltyInSecs: function() {
            return parseInt(this.timePenalty / 100);
        },
    },

    methods: {
        userClick: function(event) {
            if (!this.gameActive) {
                return;
            }

            var play = this.savePlay(event);
            var easterEggBuffer = this.foundEasterEgg;
            this.foundEasterEgg = this.hitEasterEgg(play);
            if (easterEggBuffer !== this.foundEasterEgg) {
                this.doEasterEggHit();
                return;
            }

            if (this.hitBottle(play)) {

                this.foundBottle = true;
                this.finishLevel();
                return;
            }

            this.posX = event.offsetX+'px';
            this.posY = event.offsetY+'px';

            this.lessFiveX = event.clientX+'px';
            this.lessFiveY = event.clientY+'px';
            this.lessfive = true;
            setTimeout(function () {
                this.lessfive = false;
            }.bind(this), 500);

            this.remainingTime -= this.timePenalty;
            if (!this.$parent.globalData.missTip) {
                this.$dispatch('miss');
            }
        },

        savePlay: function(event) {
            var x = parseInt(event.offsetX);
            var y = parseInt(event.offsetY);
            var play = {
                x: x,
                y: y,
                a: this.remainingTime,
                b: md5(String(x) + String(y)),
            }

            this.plays.push(play);
            return play;
        },

        finishLevel: function(event) {
            this.finishedLevelData = null;
            clearTimeout(this.timer);
            this.$dispatch('open-modal');

            var that = this;
            MyAPI.postLevel(this.gameSession, {plays: this.plays}, function(status, response) {
                that.finishedLevelData = response.data;
                that.resolveFinishedLevelData();
            });
        },

        resolveFinishedLevelData: function() {
            var current = this.finishedLevelData.shift();
            if (current) {
                if (current.component === 'lostGame') {
                    this.$dispatch('lost-game', current);
                    return;
                }
                if (current.component === 'finishGame') {
                    this.$dispatch('finish-game', current);
                    return;
                }
                this.$dispatch('set-modal', current);
                return
            }

            this.$dispatch('close-timesup', null);
        },

        giveUp: function(event) {
            clearTimeout(this.timer);

            /*this.$dispatch('lost-game');*/
            this.$dispatch('give-up');
            return;
        },

        hitEasterEgg: function(play) {
            return this.foundEasterEgg || (this.data.hashes.easterEgg.indexOf(play.b) > -1);
        },

        hitBottle: function(play) {
            return this.foundBottle || (this.data.hashes.bottle.indexOf(play.b) > -1);
        },

        loadedImg: function(event) {
            this.ready = true;
            this.preStartLevel();
            //filterNum();
            this.$nextTick( function() {
                runPan(); resizGame();
            });
        },

        preStartLevel: function() {
            var that = this;
            var timer = function() {
                setTimeout(function() {
                    that.initialCountDown -= 1;
                    if (that.initialCountDown <= 0) {
                        that.startLevel();
                    }
                    else {
                        timer();
                    }
                }, 1000);
            };

            timer();
        },

        startLevel: function() {
            this.gameActive = true;
            this.countDown();
            $('.arraste').removeClass('showing');
        },

        countDown: function() {
            var that = this;
            this.timer = setInterval(function() {
                that.remainingTime -= 1;
                if (that.remainingTime <= 0) {
                    that.remainingTime = 0;
                    that.finishLevel();
                }
            }, 10);
        },

        doEasterEggHit: function() {
            this.$dispatch('easteregg', this.data.modal.easteregg);
        },
    },

    events: {
        'resume-level': function() {
            this.countDown();
            this.gameActive = true;
        },

        'modal-opened': function() {
            clearInterval(this.timer);
            this.gameActive = false;
        },
        'touch': function(event){
            this.userClick(event);
        },
        'resolve-finished': function(event){
            this.resolveFinishedLevelData();
        },
        'extra-life': function(event){
            this.initialCountDown++;
        }
    }
});
